.admin-nav-items-container {
    display: flex;
    justify-content: space-around;
    list-style-type: none;
    margin: 0;
    padding: 0;
}

.admin-nav-container {
    padding: 10px;
    background-color: #fff;
}

.admin-nav-item {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50px;
    background-color: #F2F2F2;
    border-radius: 100px;
    padding: 0 10px;
    font-size: 16px;
    font-weight: bold;
    letter-spacing: -0.015em;
    color: #9E9E9E;
}

.admin-nav-item.active {
    background: rgba(64, 123, 255, 0.3);
    color: #407BFF;
}

.admin-nav-item p {
    margin-bottom: 0;
}

@media (min-width: 768px) {
    .admin-nav-item {
        min-width: 220px;
    }
}

@media (min-width: 1200px) {
    .admin-nav-items-container {
        justify-content: unset;
        flex-direction: column;
    }

    .admin-nav-container {
        padding: 0;
        width: 300px;
        height: calc(100vh - 70px);
    }

    .admin-nav-item {
        border-radius: 0;
        font-size: 18px;
        height: 80px;
        background-color: #fff;
        border-bottom: 1px solid #E1E1E1;
        justify-content: flex-start;
        padding-left: 40px;
    }
}

@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;700&display=swap');

* {
  font-family: 'Open Sans', sans-serif;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-bottom: 0;
  font-weight: 700;
}

ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

a,
a:hover {
  text-decoration: none;
  color: unset;
}

.base-card {
  background-color: #fff;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
}

.base-card-login {
  background-color: rgba(122, 122, 122, 0.3);
  border-radius: 8px;
  backdrop-filter: blur(4px);
  padding: 30px 10px;
  text-align: center;
}

.base-input {
  border-radius: 10px;
  font-size: 1em;
  letter-spacing: -0.015em;
  color: #263238;
  height: 50px;
}

.base-input::placeholder {
  color: #9E9E9E;
}

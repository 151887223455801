.admin-container {
    display: flex;
    flex-direction: column;
}

.admin-content {
    padding: 20px;
}

@media (min-width: 1200px) {
    .admin-container {
        flex-direction: row;
    }

    .admin-content {
        flex: 1;
    }
}

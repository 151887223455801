.btn-container {
}

.btn-container button {
    height: 3.1rem;
}

.btn h6 {
    color: #fff;
    font-size: 12px;
    text-transform: uppercase;
}

.btn-color {
    background-color: rgb(233, 121, 2);
    color: #fff;
}
.btn-color:hover {
    background-color: rgb(176, 89, 1);
}
.btn-icon-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50px;
    height: 50px;
    border-radius: 0px 10px 10px 0px;
    background-color: #33569B;
    color: #fff;
}

@media (min-width: 576px) {
    .btn-icon-container {
        width: 70px;
        height: 70px;
    }

    .btn-container button {
        width: calc(100%);
    }

    .btn h6 {
        font-size: 18px;
    }
}
.login-card {
  padding: 20px;
  font-size: 16px;
}

.login-card h1 {
  color: #fff;
  font-size: 36px;
  line-height: 1.334;
  letter-spacing: 0;
  margin: 0 0 1em;
  font-family: Roboto, Helvetica, Arial, sans-serif;
  font-weight: 400;
}

.login-link-recover {
  font-size: 1em;
  letter-spacing: -0.015em;
  color: #407bff;
  display: block;
  margin-bottom: 90px;
}

.login-link-register {
  font-weight: bold;
  font-size: 1em;
  text-align: center;
  letter-spacing: -0.015em;
  text-decoration-line: underline;
  color: #407bff;
  margin-left: 10px;
}

.not-registered {
  font-weight: bold;
  font-size: 1em;
  text-align: center;
  letter-spacing: -0.015em;
  color: #9e9e9e;
}

.signup-container {
  text-align: center;
  margin-bottom: 30px;
}

.logo-image {
  display: grid;
  place-items: center;
}

@media (min-width: 576px) {
  .login-card {
    padding: 50px;
  }
}

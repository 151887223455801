.main-nav {
  padding-left: 40px;
  min-height: 70px;
  flex-wrap: nowrap;
}

.nav-logo-text {
  color: #fff;
}

.nav-logo-text:hover {
  color: #fff;
}

.main-menu {
  display: flex;
  flex-direction: row;
  font-size: 18px;
  width: 380px;
  justify-content: end;
}

.main-menu a {
  font-style: normal;
  color: rgba(255, 255, 255, 0.5);
}

.main-menu a.active {
  font-weight: bold;
  color: #fff;
}

.main-menu a:hover {
  color: #fff;
}

.nav-login-logout {
  display: none;
}

.nav-username {
  display: none;
}

@media (min-width: 768px) {
  .nav-login-logout {
    display: unset;
    color: #fff;
    margin-right: 15px;
  }
}

@media (min-width: 992px) {
  .nav-login-logout {
    margin-right: 0px;
  }

  .nav-username {
    display: unset;
    margin-right: 20px;
  }
}

.catalog-container {
  padding: 0 2rem 0 2rem;
  height: 100vh;
  background-image: url('assets/images/bg_dashboard.webp');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.card-font {
  font-size: 16px;
}

.card-title {
  font-size: 18px;
  font-weight: bold;
}
.catalog-container {
  padding: 0 2rem 0 2rem;
  font-size: 18px;
  height: 100vh;
  background-image: url('assets/images/bg_dashboard.webp');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.catalog-title-container {
  margin-bottom: 20px;
}

.catalog-title-container h1 {
  font-size: 1em;
  letter-spacing: -0.015em;
  color: #263238;
}

.iframe-fullscreen {
  height: 100vh;
  width: 100vw;
  border: none;
  background: transparent;
}

.dashboard-container {
  padding: 0 2rem 0 2rem;
  font-size: 18px;
  height: 100vh;
  background-image: url('assets/images/bg_dashboard.webp');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}
